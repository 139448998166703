import { Icon, IconType } from 'common/Icon';

export const routes = (t, tabs) => {
    const routeList = [
        {
            icon: <Icon type={IconType.BAR_CHART}/>,
            title: t('Dashboard'),
            //path: '/admin/dashboard'
            children: [
                {
                    title: t('home'),
                    path: '/admin/home',
                    route_group: 'dashboard',
                },
                {
                    title: t('general_metrics'),
                    path: '/admin/dashboard',
                    route_group: 'dashboard',
                },
                {
                    title: t('product_metrics'),
                    path: '/admin/catalog-dashboard',
                    route_group: 'dashboard',
                }
            ]
        },
        {
            icon: <Icon type={IconType.CARD}/>,
            title: t('Payments and orders'),
            // path: '/payments',
            children: [
                {
                    title: t('Payments'),
                    path: '/payments',
                },
                {
                    title: t('Orders'),
                    path: '/admin/orders',
                },
                {
                    title: t('Orders validation'),
                    path: '/admin/orders/scan',
                }
            ]
        },
        {
            icon: <Icon type={IconType.WAITER}/>,
            title: t('Service'),
            children: [
                {
                    title: t('tables_and_sectors'),
                    path: '/settings/tables/sectors',
                },
                {
                    title: t('Table_statuses'),
                    path: '/settings/tables',
                },
                {
                    title: t('Performance camareros'),
                    path: '/admin/waiters',
                },
                {
                    title: t('Order_scheduling'),
                    path: '/admin/shifts',
                },
                {
                    title: t('BookingShifts'),
                    path: '/admin/booking-shifts',
                },
                {
                    title: t('Bookings'),
                    path: '/admin/bookings',
                },
            ],
        },
        {
            icon: <Icon type={IconType.STOCK}/>,
            title: t('Stock control'),
            // path: '/payments',
            children: [
                {
                    title: t('Current state'),
                    path: '/admin/stock-status',
                },
                {
                    title: t('Configuration'),
                    path: '/admin/stock-configuration',
                }
            ]
        },
        {
            icon: <Icon type={IconType.MENU}/>,
            title: t('menu'),
            // path: '/admin/order-and-pay',
            children: [
                {
                    title: t('link_and_pdf'),
                    path: '/menu',
                    route_group: 'menu',
                },
                {
                    title: t('products_and_categories'),
                    path: '/admin/order-and-pay',
                    route_group: 'menu',
                },
                {
                    title: t('modifiers'),
                    path: '/admin/modifiers',
                    route_group: 'menu',
                },
                {
                    title: t('highlights_banners'),
                    path: '/admin/product-highlight',
                    route_group: 'menu',
                },
                {
                    title: t('discounts'),
                    path: '/discounts',
                    route_group: 'menu',
                },
                {
                    title: t('cross_selling'),
                    path: '/admin/cross-sell',
                    route_group: 'menu',
                },
                ...(tabs ? [{
                    title: t('translations'),
                    route_group: 'menu',
                    path: '/translations/product_name',
                    /* children: [...tabs.map((tab, index) => {
                        return {
                            title: t(tab.name),
                            path: `/translations/${tab.name}`,
                            level: 3
                        }
                    })] */
                }]
                :
                []),
            ],
        },
        {
            icon: <Icon type={IconType.STAR}/>,
            title: t('Ratings_and_reviews'),
            // path: '/ratings',
            children: [
                {
                    title: t('metrics'),
                    path: '/ratings'
                },
                {
                    title: t('Reviews'),
                    path: '/ratings/reviews'
                }
            ]
        },
        {
            icon: <Icon type={IconType.PAPER_BAG}/>,
            //title: t('Delivery and takeaway'),
            title: t('delivery'), //temporary title while takeaway section is not done
            path: '/delivery' 
            /*children: [
                {
                    title: t('delivery'),
                    path: '/delivery'
                },
                {
                    title: t('takeaway'),
                    path: '/takeaway'
                }
            ]*/
        },
        {
            icon: <Icon type={IconType.GIFT}/>,
            title: t('Loyalty'),
            // path: '/loyalty',
            children: [
                {
                    title: t('Dashboard'),
                    path: '/loyalty',
                },
                {
                    title: t('Cashback'),
                    path: '/loyalty/programs',
                },
                {
                    title: t('Crm'),
                    path: '/loyalty/mails',
                }
            ],
        },
        {
            icon: <Icon type={IconType.LIGHTBULB}/>,
            title: t('my_solution'),
            children: [
                {
                    title: t('Customization'),
                    path: '/settings/customization',
                },
                {
                    title: t('QR_Codes'),
                    path: '/qr',
                },
                {
                    title: t('Facturación'),
                    path: '/admin/billing',
                },
                {
                    title: t('events'),
                    path: '/admin/events',
                },
            ],
        },
        {
            icon: <Icon type={IconType.SUBSIDY}/>,
            title: t('subsidies'),
            path: '/admin/subsidies',
        }
    ];

    const restaurants = JSON.parse(localStorage.getItem('yumminn-restaurants'));
    const showLockers = restaurants.some(restaurant => restaurant.show_lockers_page);
    const showEvents = restaurants.some(restaurant => restaurant.show_events_page);
    const showWallet = restaurants.some(restaurant => restaurant.show_wallet_page);

    if (showLockers) {
        routeList[2].children.push({
            title: t('lockers'),
            path: '/admin/lockers',
        });
    };

    if (showEvents) {
        routeList.splice(4, 0, {
            icon: <Icon type={IconType.EVENT}/>,
            title: t('events'),
            path: '/admin/event-tickets',
            children: [
                {
                    title: t('ticket_validation'),
                    path: '/admin/event-tickets',
                },
                {
                    title: t('create_events'),
                    path: '/admin/create-events',
                },
            ],
        });
    };

    if(showWallet) {
        routeList.splice(5, 0, {
            icon: <Icon type={IconType.WALLET_FOR_CHART}/>,
            title: t('wallet'),
            children: [
                {
                    title: t('settings'),
                    path: '/wallet/settings',
                    route_group: 'wallet',
                },
                {
                    title: t('topups'),
                    path: '/wallet/topups',
                    route_group: 'wallet',
                },
                {
                    title: t('metrics'),
                    path: '/wallet/metrics',
                    route_group: 'wallet',
                },
                {
                    title: t('users'),
                    path: '/wallet/users',
                    route_group: 'wallet',
                },
            ] 
        })
    }

    return routeList;
};

export const bottomRoutes = ({ handleLogout, t }) => [

    {
        icon: <Icon type={IconType.CIRCLE_LIST}/>,
        title: t('access'),
        children: [
            {
                title: t('email_list'),
                path: '/access/email_list',
                route_group: 'access',
            },
            {
                title: t('domain_list'),
                path: '/access/domain_list',
                route_group: 'access',
            },
        ],
    },
    {
        icon: <Icon type={IconType.FORK_AND_SPOON}/>,
        title: t('Restaurant'),
        path: '/settings',
    },
    {
        icon: <Icon type={IconType.VIDEO}/>,
        title: t('tutorials'),
        path: '/tutorials',
    },
    {
        icon: <Icon type={IconType.OUT}/>,
        title: t('Logout'),
        path: '/logout',
        onClick: () => { handleLogout() }
    },
]
