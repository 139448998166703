import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import StatusBadge from './StatusBadge';
import OrderType from './OrderType';
import { awareDatetimeString } from 'logic/functions';
import OrderStatusHandler from './OrderDetailComponents/OrderStatusHandler';
import OrderClientInfo from './OrderDetailComponents/OrderClientInfo';
import DeliveryAddressInfo from './OrderDetailComponents/DeliveryAddressInfo';
import OrderProductsInfo from './OrderDetailComponents/OrderProductsInfo';
import OrderGeneralNotes from './OrderDetailComponents/OrderGeneralNotes';
import OrderTotals from './OrderDetailComponents/OrderTotals';

const OrderScanDetail = ({order, products, productsModifiers, modifiersModifiers, changeStatus, statusLoading}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const listener = e => {
      if ((e.ctrlKey) && order.status == "ready_to_pick_up") {
        e.preventDefault();
		changeStatus(order.id, 'closed')
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [order]);

  const total = order.base_amount + order.tip + (order.delivery_fee_cents || 0) - order.discount - order.credit;

  return (
	<div style={{width: '100%'}}>
		<div style={{flexDirection: 'column', width: '90%', height: 'min-content'}}>
			<div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "20px"}}>
				<div style={{fontWeight: 500, fontSize: '40px', color: '#222222'}}>#<span style={{fontWeight: 700}}>{order.code}</span></div>	
				<div style={{fontWeight: 600, marginTop: "5px", fontSize: '35px', display: 'flex', alignItems: 'center', gap: "20px"}}>
					<p>{order.client_name ? order.client_name : '-'}</p>
					{order.ready_at && order.type !== "table" && 
						<p>🕚 {awareDatetimeString(order.ready_at, "Europe/Madrid", true)} - {awareDatetimeString(order.max_ready_at, "Europe/Madrid", true)}</p>
					}
				</div>
				<div style={{display: 'flex', gap: '20px', fontSize: "35px", marginTop: "10px", alignItems: 'center'}}>
					<div><OrderType type={order.type}/></div>
					<div><StatusBadge status={order.status} refunded={order.refunded} payment={order}/></div>
				</div>
				<OrderStatusHandler order={order} changeStatus={changeStatus} statusLoading={statusLoading} showTime={false}/>
			</div>
		</div>
		<div style={{backgroundColor: 'white', borderRadius: '10px', marginTop: "20px", padding: "20px", width: '90%', height: 'min-content'}}>
			<OrderProductsInfo
				products={products}
				products_modifiers={productsModifiers}
				modifiers_modifiers={modifiersModifiers}
				show_products_length={false}
				product_font_size='40px'
				product_smaller_font_size='35px'
				modifier_smaller_font_size='30px'
				show_prices={false}
				modifier_font_weight={600}
			/>
			<div style={{ marginTop: "45px"}}>
				<OrderTotals text={"total_bill"} amount={total} font_size="35px"/>
				<div style={{marginTop: "30px", display: 'flex', flexDirection: 'column', gap: "30px"}}>
					<OrderTotals text={"subtotal"} amount={order.base_amount} light={true} font_size="32px"/>
					<OrderTotals text={"tip"} amount={order.tip} light={true} font_size="32px"/>
					{order.delivery_fee_cents > 0 &&
						<OrderTotals text={"delivery_fee"} amount={order.delivery_fee_cents} light={true} font_size="32px"/>
					}
					{order.discount > 0 && order.credit === 0 &&
						<OrderTotals text={"discounts"} amount={order.discount} light={true} negative={true} font_size="32px"/>
					}
					{order.credit > 0 &&
						<OrderTotals text={order.payment_method === "wallet" ? "wallet" : "credit_cents"} amount={order.credit} light={true} negative={true} font_size="32px"/>
					}
				</div>
			</div>
		</div>
	</div>
  );
};
export default OrderScanDetail;
