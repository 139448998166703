import { routes } from "./AdminRoutes";
import { Icon, IconType } from "common/Icon";


export const admin_routes = (t, tabs) => [
    ...routes(t, tabs),
    {
        icon:  <Icon type={IconType.EYE}/>,
        title: t('Agents'),
        path: '/agents'
    },
    {
        icon:  <Icon type={IconType.BUILDING}/>,
        title: t('Companies'),
        path: '/admin/companies'
    },
    {
        icon:  <Icon type={IconType.HOUSE}/>,
        title: t('Restaurants'),
        path: '/admin/restaurants'
    },
    {
        icon:  <Icon type={IconType.INBOX}/>,
        title: t('Sales'),
        path: '/admin/sales'
    },
];


export const bottomRoutes = ({ handleLogout, t }) => [
    {
        icon: <Icon type={IconType.CIRCLE_LIST}/>,
        title: t('access'),
        children: [
            {
                title: t('email_list'),
                path: '/access/email_list',
                route_group: 'access',
            },
            {
                title: t('domain_list'),
                path: '/access/domain_list',
                route_group: 'access',
            },
        ],
    },
    {
        icon: <Icon type={IconType.FORK_AND_SPOON}/>,
        title: t('Restaurant'),
        path: '/settings'
    },
    {
        icon: <Icon type={IconType.VIDEO}/>,
        title: t('tutorials'),
        path: '/tutorials',
    },
    {
        icon: <Icon type={IconType.OUT}/>,
        title: t('Logout'),
        path: '/logout',
        onClick: () => { handleLogout() }
    },
]
